import React from 'react'
// import React, { useState } from 'react'
import Helmet from '../../components/Helmet';
import Header from '../../components/Header';
import './cssgridStyles.scss'
// import Switch from "react-switch";

function Cssgrid() {

    // const [gridChange, setGridChange] = useState(false);


    return (
        <>
            <Helmet title="demo" />
            <Header subNav={true} />
            <main id="demo">
                     <section className="section container">
                        <div className="content" id="main-content">
                            <h1>CSS Grid Example</h1>
                            <div className="commentary">
                                <p>I'm hoping this example won't take long to get the point across. In both these examples there has been NO change to markup order. I did rename css classes just to ensure specificity, but the order of the markup does not change. </p>
                                <p>The first example might be a standard page layout. The second grid example simply changes the representation of which divs occupies a space in the layout. The difference here may seem trivial at first, but the implications open up an entire world of page layout and design that has not come before.</p>
                                <p>Now we can have our cake and another slice of cake! We can mark up for accessibility and/or flow, and re-arrange it to better fit the design sensibility. But it also allows completely changing it any breakpoint. </p>
                                <p>Try it. Make your browser width smaller by dragging it. Notice Example #2 changing completely in response to the view width. Adding some Flexbox to get content to reverse, we switch at the smallest mobile breakpoint, reversing all rows. Again, with no change to markup.</p>
                                <p>Again, very simple, but coming from a world of float-based layout, CSS Grid and Flexbox makes for an exciting future in web design and layout.</p>

                            </div>
                                
                            <div className="demo-content">
                                {/* <Switch onChange={()=>{setGridChange(!gridChange)}} checked={gridChange} /> */}
                                <h2>Example #1</h2>
                                <div className="grid-container">

                                    <div className="item item-1">1</div>
                                    <div className="item item-2">2</div>
                                    <div className="item item-3">3</div>
                                    <div className="item item-4">4</div>
                                    <div className="item item-5">5</div>

                                </div>   {/* // Grid container  */}

                                <h2>Example #2</h2>
                                <div className="grid-container-2 rearrange">
                                    <div className="item rearrange-1">1</div>
                                    <div className="item rearrange-2">2</div>
                                    <div className="item rearrange-3">3</div>
                                    <div className="item rearrange-4">4</div>
                                    <div className="item rearrange-5">5</div>
                                    
                                </div>   {/* // Grid container  */}

                            </div>
                         </div>
                    </section>
            </main>
        </>
    )
}

export default Cssgrid
